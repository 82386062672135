/* ========================================================================== */
/* BLOCKS VARS */
/* Generic blocks vars */
/* ========================================================================== */

/* Section signature
 * ========================================================================== */
$section-signature-margin-bottom:			35px !default;
$section-signature-padding:					35px 45px !default;
$section-signature-border:					1px solid $gray-400 !default;
$section-signature-bg:						#f9f9f9 !default;
  
  
/* Section validation
 * ========================================================================== */
$section-valdation-text-font-size:			$font-size-md !default;
$section-valdation-text-max-width:			400px !default;
$section-valdation-text-margin:				$spacing-xxl 0 !default;

  
/* Illustration block
 * ========================================================================== */
$illustration-block-height-lg: 				500px !default;


/* KPIs list
 * ========================================================================== */
$kpis-list-spacing: 						$grid-gutter-width * 0.5 !default;
$kpis-list-margin: 							0 (-$kpis-list-spacing) $spacing-xs !default;
$kpis-list-item-bg: 						$gray-100 !default;
$kpis-list-item-padding: 					30px $spacing-xs !default;
$kpis-list-icon-color: 						$secondary !default;
$kpis-list-icon-size: 						px-to-rem(26px) !default;
$kpis-list-value-font-size: 				$font-size-xl !default;
$kpis-list-label-font-size: 				px-to-rem(13px) !default;


$content-block-shadow:						0 1px 4px 0 rgba($black, 0.08) !default;
$content-block-bg:							$white !default;
$content-block-padding:						$spacing-md !default;
$content-block-sidebar-w:					310px !default;
$content-block-border:						1px solid $gray-550 !default;
$content-block-negative-margin:				0 (-$content-block-padding) !default;