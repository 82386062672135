/* ========================================================================== */
/* ICONFONT */
/* Custom Iconfont styles */
/* ========================================================================== */

.fac
{
	@extend %fa-icon;
	font-family: 'iconfont';
	font-weight: normal;
}


	$fa-var-custom-arrow-circle-left: \EA01;

	@mixin fa-custom-arrow-circle-left { content: fa-content($fa-var-custom-arrow-circle-left); }

	.fa-custom-arrow-circle-left:before { @include fa-custom-arrow-circle-left; }

	$fa-var-custom-arrow-circle-right: \EA02;

	@mixin fa-custom-arrow-circle-right { content: fa-content($fa-var-custom-arrow-circle-right); }

	.fa-custom-arrow-circle-right:before { @include fa-custom-arrow-circle-right; }

	$fa-var-custom-circle: \EA03;

	@mixin fa-custom-circle { content: fa-content($fa-var-custom-circle); }

	.fa-custom-circle:before { @include fa-custom-circle; }

	$fa-var-custom-star: \EA04;

	@mixin fa-custom-star { content: fa-content($fa-var-custom-star); }

	.fa-custom-star:before { @include fa-custom-star; }
