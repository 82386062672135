@use "sass:math";
/* ========================================================================== */
/* TABLES */
/* Tables component styles */
/* ========================================================================== */

/* Bootstrap overrides
 * ========================================================================== */
.table
{
	@include border-bottom;
	font-size: $table-font-size; 

	&-results-count
	{
		color: $primary;
	}

	th, td, .btn-comment
	{
		vertical-align: middle;
	}

	th
	{
		background: $table-head-bg;
		color: $table-head-color;
		text-transform: uppercase;
		padding: $table-head-padding;
	}

	thead
	{
		th
		{
			border: 0;
		}
	}

	&-odd
	{
		th, td,
		+ .table-collapsible-content th, 
		+ .table-collapsible-content td { background: $table-accent-bg; }
	}

	&-collapsible-content
	{
		th, td { border-top: 0; }
	}

	&-no-border-bottom
	{
		border-bottom: 0;
	}
}


/* Sortable table
 * ========================================================================== */
.is-sortable
{
	position: relative;
	cursor: pointer;

	&:before,
	&:after
	{
		@extend %fa-icon;
		@extend .fal;
		@include pos-center-y;
		font-size: $font-size-base;
		right: 5px;
		opacity: $table-sort-icon-opacity;
	}

	&:before
	{
		content: fa-content($fa-var-angle-up);
		margin-top: -4px;
	}
	
	&:after
	{
		content: fa-content($fa-var-angle-down);
		margin-top: 4px;
	}
}

.is-sorted-asc:before,
.is-sorted-desc:after
{
	opacity: 1;
}


/* Flex table
 * ========================================================================== */
.table-flex
{   
	$base-class: &;

	min-width: $table-flex-width;
	background: $table-bg;
	font-size: $table-flex-font-size; 
	
	&-container
	{
		padding: 0 (math.div($table-flex-shadow-offset, 2));
		overflow-x: auto;
	}
	
	&-row
	{
		display: flex;
		align-items: center;
	}

	&-col
	{
		flex: 1;
	}

	&-head
	{
		color: $table-flex-head-color;
		text-transform: uppercase;
		
		#{$base-class}-col
		{
			padding: $table-flex-head-padding;
		}
	}

	&-body
	{
		color: $table-flex-body-color;
		
		#{$base-class}-row
		{
			margin: $table-flex-row-margin;
			@include shadow-block();

			&:nth-child(even)
			{
				background: $table-accent-bg;
			}
		}
		
		#{$base-class}-col
		{
			padding: $table-flex-body-padding;
		}
	}
}


/* Table's columns
 * ========================================================================== */
.col-nowrap
{
	white-space: nowrap;
}

tbody,
.table-flex-body
{
	td
	{
		.btn {
			text-transform: none;
		}
	}
}

tbody,
.table-flex-body
{
	.col-date
	{		
		color: $gray-650;
	}

	.col-id,
	.col-name,
	.col-amount
	{
		@include text-bold;
	}

	.col-icon
	{
		font-size: $font-size-md;
	}
}

.col-btn-expand,
.col-btn-actions
{
	color: $gray-600;

	i
	{
		font-size: $font-size-lg;
	}
}

td.col-btn-actions
{
	padding-left: 0;
	
	&:not(:last-child) { padding-right: 0; }
}

.col-btn-expand
{
	color: $gray-600;

	i
	{
		font-size: $font-size-lg;

		.table-active &:before
		{
			content: fa-content($fa-var-angle-up);
		}
	}
}

.col-file, .col-comment {
	text-align: center;
}

@each $col, $infos in $table-col-sizes
{
	.col-#{$col}
	{
		@if nth($infos, 1) != null
		{
			flex-basis: nth($infos, 1);
			flex-shrink: 0;
			flex-grow: 0;
			width: nth($infos, 1);
			min-width: nth($infos, 1);
		}
		text-align: nth($infos, 2);
		@if nth($infos, 3) == true { @extend .col-nowrap; }
	}
}

/* Table's total
 * ========================================================================== */
.table-total
{
	@include text-bold;
	font-size: $font-size-md;
	
	th
	{
		color: $body-color;
	}

	th, td:not(.empty)
	{
		@include border-bottom($table-border-width, $table-border-color);
	}
	
	&-balance
	{
		&, th { color: $primary; }
	}
}