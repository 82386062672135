/* ========================================================================== */
/* BUTTONS */
/* Buttons component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.btn
{
	text-transform: uppercase;

	&[disabled]
	{
		cursor: not-allowed;
	}
}


/* Add
 * ========================================================================== */
.btn-reset
{
	@include btn-reset;
}

.btn 
{
	&-delete
	{
		@include text-bold-italic;
		text-align: right;
	}

	&-add
	{
		@include text-bold;
		text-transform: uppercase;
	}

	&-delete, &-add
	{
		@extend .btn-reset;
		color: $account-info-btn-color;

		span
		{
			margin-left: 5px;
		}
	}

	&-off
	{
		@extend .btn-reset;
		font-size: $font-size-lg;
	}

	&-secondary
	{
		&:hover,
		&:focus,
		&:not(:disabled):not(:disabled):active,
		&:not(:disabled):not(:disabled).active { color: $body-color; }

		&:not(:disabled):not(:disabled) {
			// force color on active dropdowns
			.show > &.dropdown-toggle { color: $body-color; }
		}
	}

	&-default
	{
		background-color: $gray-450;
		border-color: $gray-500;
		
		&, &[disabled] { color: $gray-800; }
		
		&:hover,
		&:focus,
		&:not(:disabled):not(:disabled):active { color: $gray-900; } // wierd bootstrap override
	}

	&-badged
	{
		position: relative;

		.badge
		{
			@include position-absolute(0, 0);
			transform: translate(50%, -50%);
		}

		.badge.badge-pill-icon {
			top: -8px;
			right: 3px;
			color: $primary;
			font-size: 15px;
			
			&-big {
				top: 1px;
				color: $primary;
				right: -3px;
				font-size: 20px;
			}
		}
	}
} 

.btn-arrow-link 
{
	@extend %icon-link;
	
	&-gray
	{
		color: #666;
	}

	&:before 
	{
        @extend %fa-icon;
        @extend .fal;
        @include text-semi-bold;
        margin-right: 5px;
        content: fa-content($fa-var-angle-right);
    }
}

.input-group-append {
	.btn {
		z-index: 1;
	}
}