/* ========================================================================== */
/* ACCOUNT */
/* Account page styles */
/* ========================================================================== */

/* Layout
 * ========================================================================== */
.new-account-layout
{
	.header
	{
		&-nav
		{

		}

		&-logo
		{
			width: $header-logo-w-lg;
		}
	
		&-account
		{
			// Styles dependent of body's backround
			@each $breakpoint, $sizes in $body-bg-sizes
			{
				@include media-breakpoint-only($breakpoint) 
				{
					.btn-off
					{
						width: nth($sizes, 2);
					}
	
					&-name
					{
						color: $primary;
					}
				}
			}
		}
	}
}

/* Account create
 * ========================================================================== */
.new-account-create
{
	.form-section 
	{
		padding-bottom: $spacing-xs;
	}
}


/* Account infos
 * ========================================================================== */
.new-account-infos
{
	&-text
	{
		margin-bottom: $spacing-xs;
		font-size: $account-info-text-fontsize;
		line-height: $account-info-text-lineheight;
	}

	&-add-section
	{
		padding-top: $spacing-xs;
	}

	.form-fieldset-actions
	{
		@include media-breakpoint-up(xl) 
		{
			margin-top: $spacing;
		}
	}

	.btn-add
	{
		margin-bottom: $spacing;
	}
}

/* Account documents
 * ========================================================================== */
.new-account-documents
{
	.form-helper
	{
		margin-top: $spacing-md;
	}
}