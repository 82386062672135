/* ========================================================================== */
/* USER */
/* USER component styles */
/* ========================================================================== */


.user-avatar
{
	$context: &;

	display: flex;
	align-items: center;
	
	&-img
	{
		@include flex-center;
		@include circle;
		flex-shrink: 0;
		line-height: 1;
		text-transform: uppercase;

		& + #{$context}-name
		{
			margin-left: 5px;
		}
	}

	&-name
	{
		color: $user-avatar-name-color;
	}

	@each $type, $sizes in $user-avatar-sizes 
	{
		&-#{$type}
		{
			.user-avatar-img
			{
				@include rectangle(nth($sizes, 1));
				font-size: px-to-rem(nth($sizes, 2));
			}
		}
	}

	@each $type, $colors in $user-avatar-colors 
	{
		&-#{$type}
		{
			.user-avatar-img
			{
				background: nth($colors, 1);
				color: nth($colors, 2);
			}
		}
	}
}