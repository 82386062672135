/* ========================================================================== */
/* INVOICES */
/* Invoices page styles */
/* ========================================================================== */

/* Layout
 * ========================================================================== */


/* Transfer invoice informations
 * ========================================================================== */
.invoice-transfer-infos
{
	.amount-input
	{
		@include text-bold;
		color: $transfer-invoice-amount-input-color;
		font-size: $font-size-md;
	}

	.bank-input
	{
		color: $transfer-invoice-bank-input-color;
	}

	.form-banking-info
	{
		@include media-breakpoint-up(xl) 
		{
			padding-left: $spacing;
		}
	}

	.banking-info
	{
		&-bic,
		&-iban
		{
			display: block;
		}

		&-bic
		{
			font-size: px-to-rem(15px);
		}

		&-iban
		{
			font-size: $font-size-sm;
		}
	}
}


/* Transfer invoice current phone number
 * ========================================================================== */
.incoice-transfer-phone-number
{
	padding: $spacing-xl 0;
}