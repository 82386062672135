@use "sass:math";
/* ========================================================================== */
/* HEADER */
/* App's Header */
/* ========================================================================== */

.header
{
	/* Structure
	 * ========================================================================== */
	&-content,
	&-nav
	{
		@include flex-spacebetween(flex-end);
		align-items: center;
	}
	
	&-nav
	{
		flex: 1;
	}

	&-actions
	{
		flex-shrink: 0;
		flex-grow: 0;
	}

	&-menu a,
	&-account-name
	{
		padding: 0 0 $menu-border-spacing;
		border-bottom: $menu-border-width solid transparent;

		&:hover,
		&:focus,
		&.active
		{
			border-color: $menu-active-border-color;
			text-decoration: none;
		}
	}

	/* Logo
	 * ========================================================================== */
	&-logo
	{
		width: $header-logo-w;
		img {
			max-height: $header-logo-h;
		}
	}

	/* Name
	 * ========================================================================== */
	&-name
	{
		@include text-bold;
		color: $name-color;
		font-size: $menu-font-size * 1.2;
		text-transform: uppercase;

		@include media-breakpoint-down(sm) 
		{
			color: black;	
		}	
	}
	
	/* Tenant Name
	 * ========================================================================== */
	&-tenant-name
	{
		@include text-bold;
		color: $gray-900;
		font-size: $menu-font-size * 1.5;
		text-transform: uppercase;
	}

	/* Menu
	 * ========================================================================== */
	&-menu
	{
		@include text-bold;
		color: $menu-color;
		font-size: $menu-font-size;
		text-transform: uppercase;

		ul 
		{
			display: flex;
			margin: 0;
		}

		li
		{
			margin: 0 $menu-spacing 0 0;
		}

		a
		{
			color: inherit;
			display: block;
		}

		.active
		{
			color: $menu-active-color;
		}

		@include media-breakpoint-up(xl) 
		{
			font-size: $menu-font-size-xl;

			li
			{
				margin: 0 $menu-spacing-xl 0 0;
			}
		}
	}

	/* Account infos
	 * ========================================================================== */
	&-account
	{
		text-align: right;
		
		.btn-off
		{
			margin: 0 0 0 $spacing;
			color: $primary;
		}

		&-name
		{
			@include text-bold;
			text-transform: uppercase;

			&,
			&:hover,
			&:focus
			{
				color: $primary;
			}
		}

		&-name, .btn-off 
		{
			display: inline-block;
		}
	}
	
	/* Styles dependent of body's background
	 * ========================================================================== */
	@each $breakpoint, $sizes in $body-bg-sizes
	{
		// Defines website container width considering the current breakpoint
		$container-w: map-get($container-max-widths, $breakpoint);
		//  Defines the width needed for the content considering the amount of columns
		$col-w: nth($sizes, 1) * math.div($container-w, $grid-columns);
		//  Defines the offset
		$offset: nth($sizes, 2) + $grid-gutter-width * .5;

		@include media-breakpoint-only($breakpoint) 
		{
			&-actions
			{
				text-align: right;
			}

			&-account
			{
				.btn-off,
				&-name
				{
					&,
					&:hover,
					&:focus
					{
						color: $white;
					}
				}
			}
		}
	}
}



