/* ========================================================================== */
/* ALERTS */
/* Alerts component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.alert
{
	&-intro
	{
		@include text-bold-italic;

	}

	&-success-light {
		color: #2bae48;
	}
}
