@use "sass:math";
/* ========================================================================== */
/* LAYOUT VARS */
/* Layout's main sections vars */
/* ========================================================================== */

/* App
 * ========================================================================== */
$scrollbar-initial-width: 	17px !default;

$body-bg-sizes: 			(
								lg: (3, 50px),
								xl: (3, 50px)
							) !default;
$body-bg-color: 			#303234 !default;

/* Header
 * ========================================================================== */
$header-padding: 			$spacing-md 0 $spacing-xs !default;
$header-padding-lg: 		70px 0 $spacing-xs !default;
$header-logo-h: 			100px !default;
$header-logo-w: 			140px !default;
$header-logo-w-lg: 			175px !default;

/* Menu
 * ========================================================================== */
$menu-w:					0 !default;
$menu-font-size: 			$font-size-sm !default;
$menu-font-size-xl: 		$font-size-base !default;
$menu-color: 				$gray-500 !default;
$name-color: 				$gray-100 !default;
$menu-active-color: 		$gray-800 !default;
$menu-border-spacing: 		5px !default;
$menu-border-width: 		3px !default;
$menu-active-border-color: 	$secondary !default;
$menu-spacing: 				20px !default;
$menu-spacing-xl: 			30px !default;

/* Main
 * ========================================================================== */
$main-bg: 					$white !default;
$main-padding: 				(
								xs: $spacing,
								lg: 50px
							) !default;
$main-height: 				600px !default;

/* Footer
 * ========================================================================== */
$footer-color: 				$gray-500 !default;
$footer-font-size: 			$font-size-sm !default;
$footer-padding: 			$spacing 0 !default;
$footer-links-color: 		$footer-color !default;
$footer-copyright-color: 	$white !default;
$footer-copyright-img-w: 	100px !default;


/* Contact-Form
 * ========================================================================== */
$contact-form-width:		500px !default;
$contact-form-padding:		35px $spacing-md !default;
$contact-form-boxshadow:	$box-shadow !default;

$contact-wrapper-bg:		rgba($black, $modal-backdrop-opacity) !default;

$contact-button-width:		175px !default;
$contact-button-height:		38px !default;
$contact-button-x:			$contact-form-width - math.div($contact-button-width, 2) + math.div($contact-button-height, 2) !default;
$contact-button-y:			50% !default;
 
/* Other
 * ========================================================================== */
$notification-w:			400px !default;
$iframe-height:				650px !default;