/* ========================================================================== */
/* ACCOUNT VARS */
/* Account vars */
/* ========================================================================== */

/* New account infos
 * ========================================================================== */
$account-info-text-fontsize:				$font-size-md !default;
$account-info-text-lineheight:				28px !default;
$account-info-btn-color:					$primary-dark !default;
