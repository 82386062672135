/* ========================================================================== */
/* MODALS */
/* Modals component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.modal-footer
{
	@include media-breakpoint-down(sm)
	{
		.btn
		{
			@include btn-block;
		}
	}

	@include media-breakpoint-up(md)
	{
		@include flex-spacebetween(center);

		&-reverse
		{
			flex-direction: row-reverse;
		}
	}
}

.modal
{
	&-button-close 
	{
		@extend .btn-reset;
		font-size: $font-size-xxl;
		color: $gray-450;
	}
}

// Temporary fix https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
.modal-backdrop.fade.show {
	z-index: 1040 !important;
}