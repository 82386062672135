/* ========================================================================== */
/* STEPS */
/* Steps component styles */
/* ========================================================================== */


/* Common styles
 * ========================================================================== */
.step
{
	&-bullet
	{
		@include circle(25px);
		@include flex-center;
		flex-shrink: 0;
		color: $step-bullet-color;
		font-size: $step-bullet-font-size;
	}

	&-item.active &-bullet
	{
		@include rectangle(30px);
		@include text-bold;
		font-size: $step-bullet-font-size-active;
	}

	&-label
	{
		.active &
		{
			@include text-bold;
		}
	}

	&-bullet, &-label
	{
		font-style: normal;
		text-decoration: none;
	}
}


/* Account Steps
 * ========================================================================== */
.account-step
{
	position: relative;
	display: flex;
	align-items: center;
	padding-bottom: $steps-padding-bottom;
	color: $step-label-color;

	&.can-access
	{
		cursor: pointer;
	}

	@include media-breakpoint-down(md) 
	{
		display: none;
	}

	&:hover
	{
		color: $step-label-color;
		text-decoration: none;
	}

	&:last-child
	{
		padding-bottom: 0;
	}

	&s
	{
		margin-left: $steps-margin-left;
		border-left: $steps-line;
		font-size: $step-label-font-size;

		@include media-breakpoint-between(md, lg) 
		{
			margin-left: $steps-margin-left-lg;
			font-size: $step-label-font-size-lg;
		}
	}

	&-bullet
	{
		
		margin-right: $step-bullet-margin-right;
		margin-left: $steps-offset-left;
		background: $step-bullet-bg;

		&.fas
		{
			font-size: $step-bullet-font-custo-size;
		}

		.active &
		{
			margin-left: $steps-active-offset-left;
			color: $step-bullet-color-active;
			background: $step-bullet-bg-active;

			&.fas
			{
				font-size: $step-bullet-font-custo-size-active;
			}
		}
	}

	&-label
	{
		opacity: .5;

		.active &
		{
			opacity: 1;
		}
	}
}


/* Invoice Steps
 * ========================================================================== */
.invoice-step 
{
	flex: 1;
	text-align: center;
	counter-increment: counterStep 1;
	
	&s
	{
		display: flex;
		align-items: flex-start;
		justify-content: center;
		position: relative;
		margin: $spacing $spacing-md;
		counter-reset: counterStep;

		&:before
		{
			content: '';
			display: block;
			border-top: $step-horizontal-line-height solid $step-bullet-gray-bg;
			width: $step-horizontal-line-width;
			position: absolute;
			top: $step-horizontal-line-top;
			@include pos-center-x;
		}
	}

	&-bullet-wrap
	{
		display: flex;
		align-items: center;
		height: 50px;
		
		&:before
		{
			@extend .step-bullet;
			content: counter(counterStep) " ";
			position: relative;
			margin: 10px auto;
			background: $step-bullet-gray-bg;
			box-shadow: $step-space;


			.active &
			{
				background: $step-bullet-bg-active;
			}
		}

	}

	&-label
	{
		color: $gray-500;

		.active &
		{
			color: $step-bullet-bg;
		}
	}
}