@use "sass:math";
/* ========================================================================== */
/* APP CONTAINER */
/* App's layout's sections positionning */
/* ========================================================================== */

/* Common app structure
 * ========================================================================== */
#app
{
	min-height: 100vh;

	&,
	&-container
	{
		position: relative;
		z-index: 1;
	}

	&:after
	{
		content: '';
		display: block;
		@include position-absolute(0, 0, 0);
		background: $body-bg-color url('../../images/bg/bg_body.png') 0 0 no-repeat;

		@each $breakpoint, $sizes in $body-bg-sizes
		{
			// Defines website container width considering the current breakpoint
			$container-w: map-get($container-max-widths, $breakpoint);
			//  Defines the width needed for the content considering the amount of columns
			$col-w: nth($sizes, 1) * math.div($container-w, $grid-columns);
			//  Defines the offset needed behind the white background
			$offset: nth($sizes, 2) + $grid-gutter-width;

			$background-w: math.div(calc((100vw - #{$container-w})), 2);

			@include media-breakpoint-only($breakpoint) 
			{
				width: calc(#{$background-w} + #{$col-w} + #{$offset} - #{$scrollbar-initial-width});
			}
		}
	}

	&.contact-open
	{
		height: 100vh;
		overflow: hidden;	
	}
}

#header
{
	padding: $header-padding;

	@include media-breakpoint-up(lg) 
	{
		padding: $header-padding-lg;
	}
}

#menu
{
}

#content
{

}

#main
{
}

#footer
{
}

/* Notifications toaster
 * ========================================================================== */
.page-notifications
{
	position: fixed;
	right: $spacing;
	bottom: $spacing;
	width: $notification-w;
	z-index: 2;
}

.notification-message
{
	margin: $spacing-xs 0 0;

	&.animated
	{
		opacity: 0;
		transform: translateX(50%);
		transition: $transition-all;

		&.in
		{
			opacity: 1;
			transform: translateX(0%);
		}
	}
}

/* Contact form
 * ========================================================================== */
.contact-form
{
	max-height: 100vh;
	width: $contact-form-width;
	padding: $contact-form-padding;
	background: $white;
	box-shadow: $contact-form-boxshadow;
	overflow: auto;

	&-overlay
	{
		@include position-fixed(0, 0, 0, 0);
		display: none;
		background: $contact-wrapper-bg;
		z-index: 2;

		.contact-open &
		{
			display: block;
		}
	}	

	&-wrapper
	{
		@include pos-center-y(fixed);
		right: 0;
		transform: translate($contact-form-width, -50%);
		transition: $transition-all;
		z-index: 3;

		.contact-open &
		{
			transform: translate(0, -50%);
		}
	}

	&-button
	{
	
		@include position-absolute($contact-button-y, $contact-button-x);
		@include text-bold;
		height: $contact-button-height;
		width: $contact-button-width;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		text-transform: uppercase;
		transform: rotate(-90deg);
	}

	&-content
	{
		.btn 
		{
			width: 100%;
			text-align: center;
		}
	}
}