/* ========================================================================== */
/* FORMS */
/* Forms component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
label
{
	cursor: pointer;

	a
	{
		@include link-underline;
	}
}

/* Form labels */
.form-label
{
	color: $form-label-color;
	font-size: $form-label-font-size;
	font-weight: $form-label-font-weight;
	text-transform: uppercase;

	i
	{
		color: $gray-800;
		font-size: $font-size-md;
		margin-left: 3px;
	}
}

.form-label-alt
{
	@include text-bold-italic;
	font-size: $form-label-font-size;
	line-height: 1.5;
}

/* Form texts */
.form-text,
.invalid-feedback,
.valid-feedback
{
	@include text-italic;
}

.form-feedbacks
{
	.invalid-feedback,
	.valid-feedback { display: block; }
}

.form-text
{
	color: $gray-500;
	font-size: $form-feedback-font-size;

	a
	{
		@include link-underline;
		color: inherit;
	}
}

textarea.form-control
{
	height: $textarea-height-default;
	resize: none;
}


/* Controls overrides
 * ========================================================================== */
.form-control
{
	&-code
	{
		font-size: $font-size-md;
		letter-spacing: 10px;
	}

	&[disabled]
	{
		background-color: $gray-300;
		cursor: not-allowed;
	}

	&[readonly]
	{
		background-color: $gray-300;
		cursor: default;
	}

	&-readonly
	{
		&:disabled
		{
			cursor: default;
		}
	}

	&-alt
	{
		&:not(.is-invalid):not(.is-valid)
		{
			background: $input-alt-bg;
			border-color: $input-alt-border-color;
			color: $input-alt-color;
		
			&:focus
			{
				background: $input-alt-focus-bg;
				border-color: $input-alt-focus-border-color;
				color: $input-alt-focus-color;
			}

			&[disabled] 
			{
				background: $input-disabled-bg;
				opacity: .6; // to match others
			}
		}

		&.form-control-without-value:not(.is-invalid):not(.is-valid) {
			color: $custom-control-without-value-color;

			option {
				color: $input-alt-color;
			}
		}
	}
}

.form-check
{
	&-input
	{
		&:checked + .form-check-label:before
		{
			content: fa-content($fa-var-times);
		}
	}

	&-label
	{
		&:before
		{
			@extend %fa-icon;
			@extend .fal;
			@include rectangle($input-checkbox-size);
			border: $input-checkbox-border;
			border-radius: $input-checkbox-border-radius;
			background: $input-check-bg;
		}
	}
}

.form-radio
{
	&-input
	{
		&:checked + .form-radio-label:before
		{
			background: $gray-800;
		}
	}

	&-label
	{
		&:before
		{
			@include circle($input-radio-size);
			border: $input-radio-border;
			background: $input-check-bg;
			box-shadow: $input-radio-boxshadow;
		}
	}

	&:first-child
	{
		padding-right: $spacing;
	}
}



/* Form sections
 * ========================================================================== */

/* Form section */
.form-section
{
	@include border-top;
	padding-top: $spacing;

	&-lg
	{
		padding: 30px 0 $spacing;
	}

	&-title
	{
		@include text-bold;
		margin-bottom: $form-section-title-margin;
		color: $form-section-title-color;
		font-size: $form-section-title-font;
		text-transform: uppercase;

		.subtitle
		{
			@include text-bold-italic;
			color: $form-fieldset-title-sub-color;
			text-transform: none;

			&-small
			{
				font-size: $font-size-base;
			}
		}
	}
}

/* Form actions */
.form-actions
{
	margin: $spacing 0 0;

	@include media-breakpoint-down(sm)
	{
		.btn
		{
			@include btn-block;
		}
	}

	@include media-breakpoint-up(md)
	{
		@include flex-spacebetween(center);

		&-reverse
		{
			flex-direction: row-reverse;
		}
	}
}

/* Form fieldset */
.form-fieldset
{
	@include border;
	position: relative;
	margin: $form-fieldset-margin;
	padding: $form-fieldset-padding;
	border-radius: $border-radius;
	box-shadow: $form-fieldset-shadow;

	&-group
	{
		margin-bottom: $form-group-margin;

		// form-fieldset-group with form-section-title above
		.form-section-title + &
		{
			margin-top: $spacing-md;
		}
	}

	&-title
	{
		@include position-absolute(-15px);
		@include text-bold-italic;
		margin-left: $form-fieldset-title-offset;
		padding: $form-fieldset-title-padding;
		background: $white;
		font-size: $form-fieldset-title-fontsize;
		line-height: $line-height-base;

		.subtitle
		{
			color: $form-fieldset-title-sub-color;
			font-weight: normal;


			&-small
			{
				font-size: $font-size-base;
			}
		}
	}

	&-actions
	{
		text-align: right;
	}

	.fa-calendar-alt
	{
		color: $account-info-btn-color;
	}
}

/* Form helper */
.form-helper
{
	margin-bottom: $form-group-margin;
	color: $gray-800;
}


/* Form structure
 * ========================================================================== */
.form-block
{
	&-disabled
	{
		opacity: $form-block-disabled-opacity;
	}

	&-highlight
	{
		margin: $form-block-highlight-margin;
		padding: $form-block-highlight-padding;
		border: 1px solid $form-block-highlight-border;
		border-radius: $border-radius;
		background: $form-block-highlight-bg;
		font-size: $form-block-highlight-font-size;

		&-lg
		{
			padding: $form-block-highlight-padding-lg;
			border-color: $form-block-highlight-border-lg;
		}

		&-title
		{
			@include text-bold-italic;
			color: $primary-dark;
			font-size: $font-size-xl;
		}

		.form-label
		{
			color: $form-block-highlight-label-color;
		}

		.form-control
		{
			border-color: $input-alt-border-color;
			background: $input-alt-bg;

			&:focus
			{
				border-color: $input-focus-border-color;
			}

			.was-validated &:invalid,
			&.is-invalid
			{
				border-color: $form-feedback-invalid-color;
			}

			&[readonly]
			{
				background-color: $gray-300;
			}
		}

		.form-content
		{
			margin-top: 5px;

			a
			{
				@include link-underline;
			}
		}

		.input-group
		{
			&-text
			{
				border-color: $input-alt-border-color;
				border-left: 0;
				background: $form-block-highlight-bg;
				color: $form-block-highlight-label-color;
			}
		}
	}
}

.form-col
{
	@include media-breakpoint-up(md)
	{
		flex-basis: $form-col-width;

		&s
		{
			@include flex-spacebetween(start);
		}
	}

	@include media-breakpoint-up(xl)
	{
		flex-basis: $form-col-width-xl;
	}
}

.form-group
{
	&-row
	{
		@include media-breakpoint-up(xl)
		{
			margin-bottom: 0;
		}
	}
	
	&-no-margin
	{
		@include media-breakpoint-up(lg)
		{
			margin-bottom: 0;
		}
	}

	&-or, &-arrow
	{
		@include media-breakpoint-up(lg)
		{
			margin-top: 0;
			padding: $spacing-xs $spacing-xs 0;
		}
	}

	&-or
	{
		color: $form-group-or-color;
		text-transform: uppercase;
	}

	&-arrow
	{
		color: $primary-dark;
		font-size: $font-size-md;
	}

	&-spaced
	{
		margin-bottom: 50px;

		@include media-breakpoint-down(md)
		{
			margin-bottom: 0;
		}
	}

	&-bordered
	{
		@include border-bottom;
		padding: $spacing 0;
		margin: 0;
		
		&:first-of-type
		{
			@include border-top;
		}

		.form-check-inline
		{
			margin-bottom: 0;
		}
	}

	&.no-label
	{
		@include media-breakpoint-up(lg)
		{
			margin-top: $form-group-nolabel-margin;

			.file-uploader
			{
				margin-top: -$file-uploader-chip-margin;
			}
		}

		@include media-breakpoint-down(md)
		{
			.form-group-inline &
			{
				width: 100%;
				margin-top: 0;
				padding-top: 0;
				text-align: center;
			}
		}
	}
}

/* File uploader button
 * ========================================================================== */
.file-uploader
{
	display: flex;

	&.is-left
	{
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	&.is-loading
	{
		opacity: $file-uploader-loading-opacity;
	}

	&-action
	{
		flex-shrink: 0;
		margin: $file-uploader-action-margin;
		padding-top: $file-uploader-padding-top;
		cursor: pointer;
	}

	&-input
	{
		display: none;
	}

	&-chip-container
	{
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
	}

	&-chip
	{
		@include border(null, $file-uploader-chip-border-color);
		@include text-ellipsis;
		position: relative;
		max-width: $file-uploader-chip-width;
		margin: $file-uploader-chip-margin;
		padding: $file-uploader-chip-padding;
		border-radius: $border-radius;
		height: $file-uploader-chip-height;

		&-readonly
		{
			margin: 0;
		}
	}

	&-delete, 
	&-preview
	{
		@extend .btn-reset;
		@include pos-center-y;
		@include circle($file-uploader-delete-size);
		@include text-bold;
		right: $spacing-xs;
		background: $file-uploader-delete-bg;
		color: $white;
		font-size: $font-size-xs;

		&:before
		{
			@extend %fa-icon;
			@extend .fal;
			display: flex;
			justify-content: center;
		}
	}

	&-delete
	{
		&:before
		{
			content: fa-content($fa-var-times);
		}
	}

	&-preview
	{
		&:before
		{
			content: fa-content($fa-var-search);
		}
	}

	.btn-add
	{
		font-size: $file-uploader-add-font-size;
	}
}

/* File upload group
 * ========================================================================== */
.file-upload-group
{
	@include flex-center(column);
	border: 1px dashed $border-color;
	padding: $spacing;

	&:before
	{
		@extend %fa-icon;
		@extend .far;
		content: fa-content($fa-var-paperclip);
		color: $secondary;
		font-size: px-to-rem(48px);
		margin: 0 0 5px;
	}
}

/* Input overrides
 * ========================================================================== */
.input
{
	&-datepicker:not([readonly]), &-search
	{
		cursor: pointer;

		&.disabled
		{
			cursor: not-allowed;
		}
	}
}
