/* ========================================================================== */
/* FILTERS */
/* Filtered list view (using filter builder, table and pager) */
/* ========================================================================== */

/* Structure
 * ========================================================================== */

.active-filters,
.filter-builder
{
	display: flex;
	align-items: flex-start;

	.form-label
	{
		flex-shrink: 0;
	}

	.filter-form
	{
		.ng2-tag-input,
		.ng2-tag-input.ng2-tag-input--focused
		{
			border-bottom: none;
		}	
	}
}

.chips-wrapper
{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

/* Active filters list
 * ========================================================================== */
.active-filters
{
	&-wrap
	{
		@include flex-spacebetween(center);
		padding: $filters-padding;

		.filters-selection &
		{
			padding-left: 0;
			padding-right: 0;
		}
	}

	&-legend
	{
		margin: 0;
		
		@include media-breakpoint-up(lg) 
		{
			display: flex;
		}

		.legend-item
		{
			color: $gray-700;
			display: flex;
			align-items: center;
	
			&:before
			{
				@include bullet;
				flex-shrink: 0;
				flex-grow: 0;
			}
	
			@each $type, $colors in $chip-color-type 
			{
				&-#{$type}:before
				{
					background: nth($colors, 1);
				}
			}

			@include media-breakpoint-up(lg) 
			{
				&:not(:last-child)
				{
					margin-right: $spacing;
				}
			}
		}
	}

	&-detail
	{
		.btn
		{
			margin-left: $spacing;
		}
	}

	.form-label
	{
		padding-top: $active-filters-label-padding-top;
		color: $active-filters-label-color;
		line-height: 1;
	}
	
	.chip
	{
		margin-left: $chip-spacing;
	}
}

.btn-add-filters
{

	&-wrapper
	{
		@include flex-center;
		position: relative;
		height: $filter-add-button-height;
	}

	@extend .btn-reset;
	@include circle($filter-add-button-size);
	position: relative;
	margin-left: $filter-spacing;
	background: $filter-add-button-bg;
	color: $filter-add-button-color;
	transition: $transition-all;
	
	.fal
	{
		@include pos-center;
		@include text-semi-bold;
		font-size: $filter-add-button-font-size;
		
	}

	&:after
	{
		@include losange($filter-add-button-triangle-size, $filter-builder-bg);
		@include border-top($filter-builder-border-color);
		@include border-left($filter-builder-border-color);
		@include position-absolute($filter-add-button-triangle-top, auto, auto, $filter-add-button-triangle-left);
		z-index: 2;
		opacity: 0;
		transition: $transition-all;
	}
	
	.filter-builder-opened &
	{
		background: $filter-add-button-opened-bg;
		color: $filter-add-button-opened-color;

		.fal
		{
			&:before { content: fa-content($fa-var-times); }
		}

		&:after
		{
			transition: $transition-all;
			opacity: 1;
		}
	}
}

/* Filter builder form
 * ========================================================================== */
.filter-builder
{
	border: 1px solid $filter-builder-border-color;
	background: $filter-builder-bg;
	padding: $filters-padding;
	visibility: hidden;
	transition: $transition-all;

	.filter-builder-opened &
	{
		visibility: visible;
	}

	&-wrapper
	{
		@include toggle-animation('.filter-builder-opened &', $filter-builder-max-height, 0, $filter-builder-transition, $filter-builder-transition);
	}

	.form-label
	{
		margin-right: $spacing;
	}
	
	.form-control
	{
		margin-right: $filter-spacing;

		&.seamless {
			border: none;
			background: transparent;
		}
	}

	.btn
	{
		line-height: $input-line-height;
	}

	.ng2-tag-input
	{
		max-width: 500px;
		margin-right: $spacing-xs;
	}
}