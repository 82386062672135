/* ========================================================================== */
/* FOOTER */
/* App's footer */
/* ========================================================================== */

#footer {
	background-color: #292929;

	.footer-legal-text {
		width: 100%;
		height: 70px;
		color: #EEE;
		font-size: 15px;
		margin: 0 auto;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		padding-right: 30px;
	}
	.footer-legal-text a {
		text-decoration: none;
		color: #EEE;

		img {
			height: 30px;
			margin-left: 20px;
		}
	}
}
