/* ========================================================================== */
/* MAIN CONTAINER */
/* Layout's main container elements */
/* ========================================================================== */

/* Common structure
 * ========================================================================== */
%main-large
{
	min-height: $main-height;
}

#main
{
	background: $main-bg;
	
	@each $breakpoint, $padding in $main-padding
	{
		@include media-breakpoint-up($breakpoint) 
		{
			padding: $padding;
		}
	}
}

.page-header
{
	@include flex-spacebetween(flex-start);

	&-center
	{
		justify-content: center;
	}
}

.page-intro
{
	font-size: $font-size-md;
	margin-bottom: 1.5em;
	padding: 1.5em 0;
	@include border-top($table-border-width, $table-border-color);
	@include border-bottom($table-border-width, $table-border-color);
}


/* Other elements used everywhere
 * ========================================================================== */
.no-data
{
	@include text-italic;
	color: $gray-500;
}

.is-disabled
{
	opacity: .5;
}


/* Formatted texts
 * ========================================================================== */
.text-legend
{
	@include text-italic;
	margin: 0 0 $label-margin-bottom;
}