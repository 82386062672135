/* ========================================================================== */
/* BLOCK STYLES */
/* Generic / common blocks styles */
/* ========================================================================== */


/* Section signature
 * ========================================================================== */
.section-signature
{
	&-content
	{
		margin-bottom: $section-signature-margin-bottom;
		padding: $section-signature-padding;
		border: $section-signature-border;
		background: $section-signature-bg;
	}

	iframe
	{
		height: $iframe-height;
	}
}
   
 
/* Section validation
 * ========================================================================== */
.section-validation
{
	margin: $section-valdation-text-margin;
	text-align: center;

	&-text
	{
		max-width: $section-valdation-text-max-width;
		margin: auto;
		margin-bottom: $spacing-md;
		font-size: $section-valdation-text-font-size;
	}

	h1
	{
		margin: $spacing-md 0;
	}

	.btn-white
	{
		margin-top: $spacing-xs;
	}
}
 
/* Illustration block
 * ========================================================================== */
.illustration-block
{
	@include flex-center;

	&-lg
	{
		height: $illustration-block-height-lg;
	}
}

/* Amount recap
 * ========================================================================== */
.kpis-list
{
	display: flex;
	flex-wrap: wrap;
	margin: $kpis-list-margin;
	
	&-item
	{
		@include flex-center(column);
		@include shadow-block($kpis-list-item-bg);
		flex: 1;
		margin: 0 $kpis-list-spacing $kpis-list-spacing $kpis-list-spacing;
		padding: $kpis-list-item-padding;
		min-width: 200px;
	}

	.value
	{
		@include flex-center;
	}

	.label
	{
		@include text-italic;
		font-size: $kpis-list-label-font-size;
		color: $gray-500;
		margin: $spacing-xs 0 0;
	} 

	.icon
	{
		flex-shrink: 0;
		flex-grow: 0;
		padding: 0 $spacing-xs;		
		color: $kpis-list-icon-color;

		i { font-size: $kpis-list-icon-size; }
	}

	.amount
	{
		font-size: $kpis-list-value-font-size;
		line-height: $line-height-lg;
	}
}

/* iframe
 * ========================================================================== */
.iframe {
	&-xl {
		min-height: 500px;
	}
}


/* File preview
 * ========================================================================== */
.file-preview
{
	text-align: center;

	&-image
	{
	}

	&-iframe
	{
		height: $iframe-height;
		overflow: auto;
	}

	&-download
	{
		text-align: center;

		.btn
		{
			margin-left: $spacing;
		}
	}
}

.document-files-preview
{
	margin: 0 0 $spacing-md;
}
