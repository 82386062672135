@use "sass:math";
/* ========================================================================== */
/* COMPONENTS */
/* Components vars */
/* ========================================================================== */

/* Buttons
 * ========================================================================== */


/* Forms
 * ========================================================================== */
$input-alt-bg: 						$gray-100 !default;
$input-alt-color: 					$input-color !default;
$input-alt-border-color: 			$input-border-color !default;
$input-alt-focus-bg: 				darken($input-alt-bg, 5%) !default;
$input-alt-focus-border-color: 		darken($input-alt-border-color, 10%) !default;
$input-alt-focus-color: 			$input-alt-color !default;

$form-label-color: 						$gray-500 !default;
$form-label-color: 						$gray-500 !default; 
$form-label-font-size: 					px-to-rem(13px) !default;
$form-label-font-weight:				$font-weight-bold !default;


$form-col-width:						calc(50% - #{$spacing}) !default;
$form-col-width-xl:						calc(50% - #{$spacing-md}) !default;

$form-group-margin:						45px !default;
$form-group-nolabel-margin:				calc(#{$form-label-font-size} * #{$line-height-base} + #{$label-margin-bottom} + 1px) !default;

$form-group-or-color:					$gray-450 !default;
$form-group-inline-input-width:			300px !default;

$form-fieldset-margin:					15px 0 30px !default;
$form-fieldset-padding:					25px 30px !default;
$form-fieldset-shadow:					0px 1px 2px 0 rgba($black, 0.06) !default;
$form-fieldset-title-padding:			0 5px !default;
$form-fieldset-title-offset:			-5px !default;
$form-fieldset-title-fontsize:			$font-size-lg !default;
$form-fieldset-title-sub-color:			$gray-600 !default;

$form-section-title-color:				$primary-dark !default;
$form-section-title-font:				$font-size-base !default;
$form-section-title-margin:				$form-group-margin-bottom !default;

$form-block-disabled-opacity: 			.5 !default;

$form-block-highlight-border: 			#dbdbdb !default;
$form-block-highlight-border-lg:	 	#cfd7e9 !default;
$form-block-highlight-bg: 				#f9f9f9 !default;
$form-block-highlight-margin: 			0 0 $form-group-margin-bottom !default;
$form-block-highlight-padding: 			15px $spacing 0 !default;
$form-block-highlight-padding-lg: 		30px $spacing-md !default;
$form-block-highlight-font-size:		px-to-rem(13px) !default;
$form-block-highlight-label-color:		$gray-500 !default;

$textarea-height-default:				140px !default;


/* Inputs
 * ========================================================================== */
$input-alt-bg: 							$white !default;
$input-alt-border-color: 				$input-border-color !default;

$input-check-border-color:				$gray-300 !default;
$input-check-bg:						$gray-100 !default;

$input-checkbox-size:					20px !default;
$input-checkbox-border:					$border-width solid $input-check-border-color !default;
$input-checkbox-border-radius:			$border-radius !default;

$input-radio-size: 						15px !default;
$input-radio-border:					4px solid $input-check-bg !default;
$input-radio-boxshadow:					0 0 0 1px $input-check-border-color !default;


/* Tabs
 * ========================================================================== */

/* Table
 * ========================================================================== */
$table-sort-icon-opacity:			.3 !default;

$table-col-id-width:				130px !default;
$table-col-short-id-width:			80px !default;
$table-col-name-width:				null !default;
$table-col-date-width:				150px !default;
$table-col-amount-width:			140px !default;
$table-col-amount-lg-width:			180px !default;
$table-col-status-width:			170px !default;
$table-col-icon-width:				60px !default;
$table-col-btn-expand-width:		70px !default;

									// width, align, isNowrap
$table-col-sizes: 					(
										id: ($table-col-id-width, null, false),
										short-id: ($table-col-short-id-width, null, false),
										name: ($table-col-name-width, null, false),
										date: ($table-col-date-width, null, true),
										amount: ($table-col-amount-width, right, true),
										amount-lg: ($table-col-amount-lg-width, right, true),
										status: ($table-col-status-width, null, true),
										icon: ($table-col-icon-width, center, true),
										btn-expand: ($table-col-btn-expand-width, right, false)
									) !default;
 
$table-flex-bg:						$table-bg !default;
$table-flex-shadow-offset:			6px !default;
									// Min width calculation for scrolling
$table-flex-width:					map-get($container-max-widths, xl) - $grid-gutter-width - map-get($main-padding, lg) * 2 - $table-flex-shadow-offset !default;
$table-flex-font-size:				$table-font-size !default;
$table-flex-head-color:				$table-head-color !default;
$table-flex-head-padding:			$table-head-padding !default;
$table-flex-body-padding:			$table-cell-padding !default;
$table-flex-body-color:				$gray-700 !default;
$table-flex-row-margin:				0 0 $spacing-xs !default;


/* Steps
 * ========================================================================== */
$steps-padding-bottom:					70px !default;
$steps-margin-left:						40px !default;
$steps-margin-left-lg:					10px !default;
$steps-line:							2px solid $gray-650 !default;
$steps-offset-left:						-13px !default;
$steps-active-offset-left:				-16px !default;

$step-bullet-margin-right:				$spacing !default;
$step-bullet-color:						$white !default;
$step-bullet-color-active:				$white !default;
$step-bullet-bg:						$gray-650 !default;
$step-bullet-bg-active:					$secondary !default;
$step-bullet-gray-bg:					$gray-450 !default;

$step-bullet-font-size:					$font-size-sm !default;
$step-bullet-font-size-active:			$font-size-md !default;
$step-bullet-font-custo-size:			$step-bullet-font-size - px-to-rem(2px) !default;
$step-bullet-font-custo-size-active:	$step-bullet-font-size-active - px-to-rem(3px) !default;

$step-label-color:						$white !default;
$step-label-font-size:					px-to-rem(15px) !default;
$step-label-font-size-lg:				.9rem !default;

$step-horizontal-line-top:				25px !default;
$step-horizontal-line-height:			2px !default;
$step-horizontal-line-width:			math.div(100%, 3) * 2 !default;

$step-space:							0px 0px 0px 3px rgba($white,1) !default;


/* File Uploader
 * ========================================================================== */
$file-uploader-padding-top:				$spacing-xs !default;
$file-uploader-chip-height:				$input-height !default;
$file-uploader-chip-margin:				math.div($input-padding-x, 2) !default;
$file-uploader-chip-padding:			$input-padding-y $spacing-md $input-padding-y $input-padding-x !default;
$file-uploader-chip-width:				220px !default;
$file-uploader-chip-border-color:		$gray-400 !default;
$file-uploader-action-margin:			$file-uploader-chip-margin !default;
$file-uploader-delete-bg:				$dark-blue !default;
$file-uploader-delete-size:				20px !default;
$file-uploader-add-font-size:			px-to-rem(13px) !default;
$file-uploader-loading-opacity:			.5 !default;

/* Status */
/* ========================================================================== */
$status-font-size:						px-to-rem(13px) !default;
$status-colors:	    					(
											none: $gray-800,
											pending: $orange,
											confirmed: $pale-blue,
											active: $green,
											inactive: $gray-blue,
											blocked: $red-light,
											closed: $gray-800
										) !default;

/* Popover */
/* ========================================================================== */
$popover-margin-top:				15px !default;

/* Chips */
/* ========================================================================== */
$chip-border-radius:				25px !default;
$chip-border:						$border-width solid $gray-300 !default;
$chip-height:						30px !default;
$chip-spacing:						4px !default;
$chip-font-size:					px-to-rem(13px) !default;
$chip-padding-y:					7px !default;
$chip-padding-x:					$spacing-xs !default;
$chip-btn-color:					$gray-300 !default;
$chip-hover-color:					$gray-100 !default;
$chip-content-width:				500px !default;

$chip-color:						$gray-600 !default;
$chip-bg-color:						#f1f1f1 !default;
$chip-color-seller:					#e78200 !default;
$chip-color-debtor:					#a1008f !default;
$chip-color-accounting:				#99c418 !default;
$chip-color-bg-seller:				#fff2e1 !default;
$chip-color-bg-debtor:				#ffe6fc !default;
$chip-color-bg-accounting:			#f3f9e3 !default;

$chip-active-bg:					$primary !default;
$chip-active-border-color:			$primary-dark !default;
$chip-active-color:					$white !default;

$chip-color-type: (
									seller: ($chip-color-seller, $chip-color-bg-seller),
									debtor: ($chip-color-debtor, $chip-color-bg-debtor),
									accounting: ($chip-color-accounting, $chip-color-bg-accounting)
								) !default;

/* Filters */
/* ========================================================================== */
$filters-padding: 					$spacing !default;
$filter-spacing:					$spacing-xs !default;

$active-filters-label-color: 		$gray-300 !default;
$active-filters-label-padding-top:	$chip-spacing + $chip-padding-y !default;

$filter-builder-border-color:		#cdd3e5 !default;
$filter-builder-bg:					$gray-200 !default;
$filter-builder-max-height:			200px !default;
$filter-builder-transition:			all .3s ease !default;
$filter-builder-input-width-xs:		110px !default;
$filter-builder-input-width-sm:		160px !default;

$filter-add-button-size:			25px !default;
$filter-add-button-font-size:		px-to-rem(14px) !default;
$filter-add-button-bg:				$secondary !default;
$filter-add-button-color:			$white !default;
$filter-add-button-opened-bg:		$gray-800 !default;
$filter-add-button-opened-color:	null !default;
$filter-add-button-height:			$chip-height + $chip-spacing * 2 !default;

$filter-add-button-triangle-size:	10px !default;
$filter-add-button-triangle-top:	48px !default;
$filter-add-button-triangle-left:	7px !default;

/* Cards
  * ========================================================================== */
$card-item-padding:					15px $spacing !default;
$card-item-bg-color:				$white !default;
$card-item-border:					$border-width solid $gray-350 !default;
$card-item-border-radius:			$border-radius !default;
$card-item-date-color:				$gray-300 !default;
$card-item-toggle-color:			$gray-450 !default;
$card-item-toggle-hover-color:		$secondary !default;

$card-contact-padding:				30px !default;
$card-contact-spacing:				25px !default;
$card-contact-icon-margins:			15px !default;
$card-contact-color-item:			$gray-700 !default;
$card-contact-color-item-secondary:	$gray-450 !default;
$card-contact-color-icon:			$primary !default;
$card-contact-color-icon-secondary:	$secondary !default;
$card-contact-title-size:			$font-size-lg !default;
$card-contact-subtitle-size:		px-to-rem(13px) !default;
$card-contact-icon-size:			px-to-rem(30px) !default;
$card-contact-name-size:			px-to-rem(15px) !default;
$card-contact-name-height:			$card-contact-name-size * $line-height-base !default;

 /* Comments
  * ========================================================================== */
$comment-label-color:				$gray-500 !default;