/* ========================================================================== */
/* CARDS */
/* Cards styles */
/* ========================================================================== */

/* Structure
 * ========================================================================== */
@mixin card-base (
	$border: $card-item-border,
	$background: $card-item-bg-color,
	$padding: $card-item-padding,
    $border-radius: $card-item-border-radius
)
{
	border: $border;
    border-radius: $border-radius;
	background: $background;
	padding: $padding;
}

%card-base
{
    @include card-base;
}

.card-item
{
	$context: &;

    @extend %card-base;
    margin-bottom: $spacing-xs;
	position: relative;

	&-head
	{
        @include flex-spacebetween(top);
        @include text-semi-bold;
        margin-bottom: 5px;
    }

	&-body
	{
        display: flex;
        align-items: center;
		flex-wrap: wrap;

		&-more
		{
			margin: $spacing-xs 0 0;
		}
    }

	&-date
	{
        flex-shrink: 0;
        color: $card-item-date-color;
	}

	&-actions
	{
		width: 48px;
		flex-shrink: 0;
		visibility: hidden;
		display: inline-block;
		color: $card-item-date-color;

		button {
			padding: 0 0.25rem 0.25rem 0.25rem;
			color: $card-item-date-color;

			&:hover {
				color: $nav-tabs-link-active-color;
			}
		}
	}
	&:hover .card-item-actions
	{
		visibility: visible;
	}

	&-reply
	{
		* {
			color: $card-item-date-color;
		}
	}

	&-toggle
	{
		@extend .btn-reset;
		@include position-absolute(null, $spacing-xs, 5px);
		color: $card-item-toggle-color;
		font-size: $font-size-md;
		padding: 3px $spacing-xs;

		&:before
		{
			@extend %fa-icon;
			@extend .far;
			content: fa-content($fa-var-angle-up);
		}

		&:hover
		{
			color: $card-item-toggle-hover-color;
		}
	}

	&.is-collapsed
	{
		#{$context}-toggle:before
		{
			content: fa-content($fa-var-angle-down);
		}
	}
}

/* Contact card
 * ========================================================================== */
.card-contact
{
	@extend %card-base;
	margin-bottom: $spacing;
	padding: $card-contact-padding;
	text-align: left;

	&-header
	{
		margin-bottom: $card-contact-spacing;
		padding-bottom: $card-contact-spacing;
		border-bottom: $card-item-border;

		.default-icon
		{
			position: absolute;
			top: 2.5em;
			right: 1.5em;
		}
	}

	&-icon
	{
		display: flex;
		align-items: center;
		padding-bottom: $card-contact-spacing;

		.title,
		.icon
		{
			color: $card-contact-color-icon;
		}

		.title
		{
			@include text-bold;
			margin-bottom: 0;
			font-size: $card-contact-title-size;
		}

		.subtitle
		{
			@include text-italic;
			color: $card-contact-color-icon-secondary;
			font-size: $card-contact-subtitle-size;
		}

		.icon
		{
			padding-right: $spacing-xs;
			font-size: $card-contact-icon-size;
			flex-shrink: 0;
		}
	}
	&-infos {
		height: 40px;
	}

	&-person
	{
		font-size: $card-contact-name-size;

		.name
		{
			@include text-ellipsis(100%);
		}

		.company
		{
			@include text-ellipsis(100%);
			@include text-italic;
			height: $card-contact-name-height;
			color: $card-contact-color-item-secondary;
		}
	}

	&-content
	{

		&-item
		{
			display: flex;
			align-items: baseline;
			margin-bottom: $spacing;
			color: $card-contact-color-item;

			&.action
			{
				justify-content: flex-end;
			}

			&:last-child
			{
				margin-bottom: 0;
			}

			i, input[type=checkbox]
			{
				width: $font-size-base;
				flex-shrink: 0;
				margin-right: $card-contact-icon-margins;
				color: $card-contact-color-item-secondary;
				text-align: center;
			}
		}

	}

}

/* Cards Slider
 * ========================================================================== */
.cards-slider
{
	&-header
	{
        @include flex-spacebetween(center);
	}

	&-actions {

	}

	&-controls
	{
        margin-left: $spacing;

		button
		{
			@extend .btn-reset;

			&:before
			{
				@extend %fa-icon;
				@extend .far;
				font-size: $font-size-lg;
			}
		}

		.btn-prev
		{
			&:before
			{
				content: fa-content($fa-var-angle-left);
			}
		}

		.btn-next
		{
			margin-left: $spacing-xs;

			&:before
			{
				content: fa-content($fa-var-angle-right);
			}
		}
	}
}
