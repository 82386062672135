/* ========================================================================== */
/* GLOBAL */
/* Basic elements styles */
/* ========================================================================== */

/* Generic markups
 * ========================================================================== */

html
{
	// Forces font-size, avoiding user custom preferences to break the UI
	font-size: $font-size-base-px;
	letter-spacing: $letter-spacing-base;
}

body
{
	overflow-x: hidden;
	overflow-y: scroll; // not very aesthetic but prevents background "jumping" on scrollbar apparition
	min-height: 100vh;
}

a
{
	cursor: pointer;
}

ol, ul
{
	@include list-reset;
}

button
{
	cursor: pointer;
	letter-spacing: $letter-spacing-base;
}

img
{
	max-width: 100%;
}

iframe
{
	width: 100%;
	border: 0;
}

hr
{
	margin: 30px auto;
	opacity: .8;
}

label
{
	.btn-link
	{
		color: inherit;
		text-decoration: underline;
	}
}