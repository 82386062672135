/* ========================================================================== */
/* POPOVER */
/* Popover component styles */
/* ========================================================================== */

.popover
{
	box-shadow: $popover-box-shadow;
}

.popover-content
{
	& + &
	{
		margin-top: $popover-margin-top;
	}

	&-label
	{
		@extend .form-label;
	}

	&-text
	{
		@include text-bold;
		font-size: $font-size-base;
		line-height: 1;
	}
}