/* ========================================================================== */
/* STATUS */
/* Status component styles */
/* ========================================================================== */


.status-info
{
	@include text-bold-italic;
	display: flex;
	align-items: center;
	font-size: $status-font-size;
	white-space: nowrap;
	
	&:before
	{
		@include circle($spacing-xs);
		content: '';
		flex-shrink: 0;
		margin-right: 5px;
	}

	@each $type, $color in $status-colors
	{
		&-#{$type}
		{
			color: $color;

			&:before			
			{
				background: $color;
			}
		}
	}	
}